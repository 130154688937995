import { Inject, Injectable } from '@angular/core';
import * as StellarSDK from '@stellar/stellar-sdk';
import {
  distinctUntilChanged,
  firstValueFrom,
  map,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  timer,
  withLatestFrom
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { deleteEntities, selectEntity, UIEntitiesRef, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { LedgerDataService } from '../ledger-data/ledger-data.service';
import { ProfileRepository } from '../../../state/profile.repository';
import BigNumber from 'bignumber.js';
import { CurrenciesRepository, Currency } from '../../../state/currencies.repository';
import { RpcService } from '../rpc/rpc.service';
import { FxDaoSdkService } from '../fxdao-sdk/fxdao-sdk.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileRpcService {

  updateCurrencyData$: Subject<void> = new Subject<void>();

  constructor(
    @Inject('env')
    private readonly env: typeof environment,
    private readonly profileRepository: ProfileRepository,
    private readonly currenciesRepository: CurrenciesRepository,
    private readonly fxDaoSdkService: FxDaoSdkService,
  ) { }

  updaterSubscription: Subscription = this.updateCurrencyData$.asObservable()
    .pipe(switchMap(() => this.profileRepository.publicKey$.pipe(distinctUntilChanged())))
    .pipe(switchMap((publicKey) => {
      return !publicKey
        ? of(null)
        : timer(0, 15000)
          .pipe(switchMap(() => {
            return Promise.all([
              firstValueFrom(this.currenciesRepository.currencies$),
            ]);
          }))
          .pipe(map(([currencies]) => {
            return [currencies, publicKey] as [Currency[], string];
          }));
    }))
    .subscribe((data) => {
      if (!!data) {
        const [currencies, publicKey] = data;
        for (const currency of currencies) {
            this.getVaultStats({ currency, user: new StellarSDK.Address(publicKey) }).then().catch();
        }
      }
    });

  async getVaultStats(params: { user: StellarSDK.Address; currency: Currency }) {
    try {
      const vault = await this.fxDaoSdkService.vaultsContract.getVault({
        user: params.user.toString(),
        denomination: params.currency.denomination,
      })

      this.profileRepository.store.update(
        upsertEntities([{
          _id: params.currency._id,
          total_col: new BigNumber(vault.total_collateral.toString()).dividedBy('10000000').toFixed(7),
          total_debt: new BigNumber(vault.total_debt.toString()).dividedBy('10000000').toFixed(7),
        }]),
      );
    } catch (e) {
      this.profileRepository.store.update(deleteEntities([params.currency._id]));
    }
  }
}
