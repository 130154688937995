import { AfterViewInit, Component } from '@angular/core';
import { ProfileService } from './core/services/profile/profile.service';
import { CurrenciesService } from './core/services/currencies/currencies.service';
import { CurrenciesRpcService } from './core/services/currencies/currencies-rpc.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  constructor(
    private readonly profileService: ProfileService,
    private readonly currenciesService: CurrenciesService,
    private readonly currenciesRpcService: CurrenciesRpcService,
  ) { }

  ngAfterViewInit() {
    this.currenciesRpcService.updateCurrencyData$.next();
  }
}
