import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { devTools } from '@ngneat/elf-devtools';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ApplicationRef } from '@angular/core';
import { enableElfProdMode } from '@ngneat/elf';

if (!environment.production) {
  devTools();
} else {
  enableElfProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule).then((moduleRef) => {
  devTools({
    postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick()
  });
})
