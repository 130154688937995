import { Networks } from '@stellar/stellar-sdk';

export const environment = {
  production: false,
  horizonUrl: 'https://horizon-testnet.stellar.org',
  sorobanRpcUrl: 'https://soroban-testnet.stellar.org',
  accountForSimulations: 'GDTW6WIS5SKEITK6KZMJSPD7FK7Z5B7WSGYCGMQD6K4YH7RIHQYQSXJB',
  vaultsContractId: 'CAO3CG3IKTWQT6KCQHFOH55SBUAOWF2DIMW6PQHXKRLGYHVX23SBBNXU',

  lockingPool: 'CDCART6WRSM2K4CKOAOB5YKUVBSJ6KLOVS7ZEJHA4OAQ2FXX7JOHLXIP',
  rewardedPools: [],

  // These values are deprecated now:
  usdSafetyPoolContractId: 'CBQ2IAUD6I2H2OTCSEFEO426JXMWA3LC6FCEPSOFIZKKBIQCDLNP346U',
  usdStablePool: 'CCWJMQSABWOG33IMCV4PL3V6HPPLSXHMQHWXKQKDWX3T4IWG26OQLD5Q',
  stablePoolsAssets: [
    {
      code: 'USDx',
      contract: 'CB3BKDDK3A4CS54Q3WTS5LCRXWA2GRTNELX5OR3E6JN433KVJYIJLOS7',
      denomination: 'USD',
    },
    {
      code: 'USDC',
      contract: 'CBNEWOYSLLF5AWZOCYKGPTNHRQESC224DLBAGB7KYPCTNZHXI4CSFUNF',
      denomination: 'USD',
    },
  ],
  eurSafetyPoolContractId: 'CBF4G4HHPP2QYU5DMQDY27SXF3F37XJRP7BEUUNC4AAX4YH5BQFGBWXM',
  // END

  defaultFee: '10000000',
  networkPassphrase: Networks.TESTNET,
  usdAsset: 'USDx:GBBTSBSV55VUI6KQ32JARRR65HV7AJNOEZR7P5HLNT2EZIXHVDPDA5JW',
  usdContractId: 'CA2QJKOZF6WE3C45FCYDWB45337BKENLUU4EREWWXRIMHKWJSH6EEWVO',
  eurAsset: 'EURx:GBBTSBSV55VUI6KQ32JARRR65HV7AJNOEZR7P5HLNT2EZIXHVDPDA5JW',
  eurContractId: 'CBA2S6NROG4PN36FSFZTWGD4JVQDCUYBMCW2H4J64JCGH7ZSQYTAIZ54',
  gbpAsset: 'GBPx:GBBTSBSV55VUI6KQ32JARRR65HV7AJNOEZR7P5HLNT2EZIXHVDPDA5JW',
  gbpContractId: 'CDYP7LY3OIKHFVDID3CO6MQJ45T37N2G63NYXN33OQJPLW3X2PYRFHVT',
  oracleContract: 'CBVLRY2DCYXNIY246JH7RPRMB2QE3LGFQVM7WMFOTBPTFISHEAJTJ6X7',

  assetsIssuer: 'GBBTSBSV55VUI6KQ32JARRR65HV7AJNOEZR7P5HLNT2EZIXHVDPDA5JW'
};
