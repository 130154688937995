import { Inject, Injectable } from '@angular/core';
import {
  AlbedoModule,
  ButtonThemes,
  FreighterModule,
  LobstrModule,
  ModalThemes,
  StellarWalletsKit, WalletNetwork,
  XBULL_ID,
  xBullModule
} from '@creit.tech/stellar-wallets-kit';
import { ProfileRepository } from '../../../state/profile.repository';
import * as StellarSDK from '@stellar/stellar-sdk';
import { Transaction } from '@stellar/stellar-sdk';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { RpcService } from '../rpc/rpc.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { parseError, ParseErrorType } from '../../../../libs/FxDAO-SDK';
import {
  WalletConnectAllowedMethods,
  WalletConnectModule
} from '@creit.tech/stellar-wallets-kit/modules/walletconnect.module';


@Injectable({
  providedIn: 'root'
})
export class WalletService {
  horizonServer: StellarSDK.Horizon.Server = new StellarSDK.Horizon.Server(
    this.env.horizonUrl,
    { allowHttp: !this.env.production }
  );
  rpcServer: StellarSDK.SorobanRpc.Server = new StellarSDK.SorobanRpc.Server(
    this.env.sorobanRpcUrl,
    { allowHttp: !this.env.production }
  );

  kit = new StellarWalletsKit({
    theme: ModalThemes.LIGHT,
    buttonTheme: ButtonThemes.LIGHT,
    network: this.env.networkPassphrase as any,
    selectedWalletId: XBULL_ID,
    modules: [
      new xBullModule(),
      new FreighterModule(),
      new AlbedoModule(),
      new LobstrModule(),
      new WalletConnectModule({
        name: 'FxDAO',
        description: 'A decentralized borrowing protocol for the issuance of decentralized stablecoins on Stellar',
        url: 'https://fxdao.io',
        icons: ['https://assets.fxdao.io/brand/FxDAO-logo.svg'],
        projectId: '69393bb3031125dcd380d2a8f3ba7dd6',
        method: WalletConnectAllowedMethods.SIGN,
        network: this.env.networkPassphrase as unknown as WalletNetwork,
        onSessionDeleted: (): void => {
          this.kit.disconnect().then()
        },
      })
    ],
  });

  constructor(
    @Inject('env')
    private readonly env: typeof environment,
    private readonly profileRepository: ProfileRepository,
    private readonly rpcService: RpcService,
    private readonly nzMessageService: NzMessageService,
  ) {}

  async loadAccount(): Promise<StellarSDK.Horizon.AccountResponse> {
    const publicKey = await firstValueFrom(this.profileRepository.publicKey$);

    if (!publicKey) {
      throw new Error('Public key is not loaded');
    }

    return this.horizonServer.loadAccount(publicKey);
  }

  async sign(xdr: string) {
    const publicKey = await firstValueFrom(this.profileRepository.publicKey$);

    if (!publicKey) {
      throw new Error('Public key is not loaded');
    }

    return this.kit.signTransaction(xdr, {
      address: publicKey,
      networkPassphrase: this.env.networkPassphrase as any,
    });
  }

  async signAndSend(xdr: string) {
    const result = await this.sign(xdr);
    const signed = new StellarSDK.Transaction(result.signedTxXdr, this.env.networkPassphrase);

    const response = await this.rpcServer.sendTransaction(signed);
    if (response.errorResult) {
      throw response.errorResult;
    }
    await this.rpcService.waitUntilTxApproved(response.hash);
  }

  async simulateAndCheck(xdr: string, errorType: ParseErrorType): Promise<{ finalXDR: string }> {
    const tx = new Transaction(xdr, this.env.networkPassphrase);
    const sim = await this.rpcServer.simulateTransaction(tx);

    if (StellarSDK.SorobanRpc.Api.isSimulationError(sim)) {
      throw parseError(errorType, sim);
    }

    if (!StellarSDK.SorobanRpc.Api.isSimulationRestore(sim)) {
      const preparedTx = StellarSDK.SorobanRpc.assembleTransaction(tx, sim);

      return { finalXDR: preparedTx.build().toXDR() };
    }

    const account = await this.loadAccount();
    let fee: number = parseInt(this.env.defaultFee);
    fee += parseInt(sim.restorePreamble.minResourceFee);

    const restoreTx = new StellarSDK.TransactionBuilder(account, { fee: fee.toString() })
      .setNetworkPassphrase(this.env.networkPassphrase)
      .setSorobanData(sim.restorePreamble.transactionData.build())
      .addOperation(StellarSDK.Operation.restoreFootprint({ }))
      .setTimeout(0)
      .build();

    this.nzMessageService.warning('Expired data on the ledger, you first need to restore data and try again.', {
      nzDuration: 5000
    });

    return {
      finalXDR: restoreTx.toXDR(),
    }
  }
}
