import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  Account,
  Address,
  Contract,
  nativeToScVal,
  Networks,
  scValToBigInt,
  scValToNative,
  SorobanRpc, TransactionBuilder, xdr
} from '@stellar/stellar-sdk';
import { VaultsContract } from '../../../../libs/FxDAO-SDK';

@Injectable({
  providedIn: 'root'
})
export class FxDaoSdkService {
  vaultsContract: VaultsContract = new VaultsContract({
    stellarSDK: {
      SorobanRpc,
      Account,
      Address,
      Contract,
      nativeToScVal,
      scValToBigInt,
      scValToNative,
      TransactionBuilder,
      xdr,
    },
    network: this.env.networkPassphrase,
    simulationAccount: this.env.accountForSimulations,
    defaultFee: this.env.defaultFee,
    rpc: this.env.sorobanRpcUrl,
    allowHttp: true,
    contractId: this.env.vaultsContractId,
  });

  constructor(
    @Inject('env')
    private readonly env: typeof environment,
  ) { }

}
