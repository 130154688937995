<nz-layout class="flex min-h-screen">

  <ng-template #drawerTemplate let-data let-drawerRef="drawerRef">
    <ul nz-menu nzMode="inline">
      <li nz-menu-group nzTitle="Sections">
        <ul>
          <li (click)="drawerRef.close()" nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/">
            <i class="fa fa-home mr-2"></i> Home
          </li>

<!--          <li (click)="drawerRef.close()" nz-menu-item nzMatchRouter routerLink="/vaults">-->
<!--            <i class="fa fa-vault mr-2"></i> Vaults-->
<!--          </li>-->

          <li (click)="drawerRef.close()" nz-menu-item nzMatchRouter routerLink="/redeem">
            <i class="fa fa-shop mr-2"></i> Redeem
          </li>

          <li (click)="drawerRef.close()" nz-menu-item nzMatchRouter routerLink="/liquidations">
            <i class="fa fa-sign-hanging mr-2"></i> Liquidations
          </li>

          <li (click)="drawerRef.close()" nz-menu-item nzMatchRouter routerLink="/locking-pools">
            <i class="fa fa-swimming-pool mr-2"></i> Rewards Pools
          </li>

<!--          <li (click)="drawerRef.close()" nz-menu-item nzMatchRouter routerLink="/safety-pools">-->
<!--            <i class="fa fa-shield mr-2"></i> Safety Pools-->
<!--          </li>-->

<!--          <li (click)="drawerRef.close()" nz-menu-item nzMatchRouter routerLink="/swaps">-->
<!--            <i class="fa fa-exchange mr-2"></i> Swaps-->
<!--          </li>-->

          <li (click)="drawerRef.close()" nz-menu-item nzDisabled>
            <i class="fa fa-people-group mr-2"></i> Governance
          </li>
        </ul>
      </li>

      <li nz-menu-group nzTitle="About">
        <ul>
          <a (click)="drawerRef.close()" nz-menu-item nzMatchRouterExact href="https://fxdao.io/docs" target="_blank">
            <i class="fa fa-book mr-2"></i> Docs
          </a>

          <a (click)="drawerRef.close()" nz-menu-item nzMatchRouterExact href="https://discord.com/invite/Ftrx9X6YRk" target="_blank">
            <i class="fa-brands fa-discord mr-2"></i> Discord
          </a>

          <a (click)="drawerRef.close()" nz-menu-item nzMatchRouterExact href="https://twitter.com/FxDAO_io" target="_blank">
            <i class="fa-brands fa-twitter mr-2"></i> Twitter
          </a>
        </ul>
      </li>
    </ul>
  </ng-template>

  <nz-layout class="min-h-screen w-full flex flex-col">
    <nz-header class="flex w-full bg-white shadow shadow-lg sticky z-50 top-0 px-4">
      <div class="w-full flex justify-between mx-auto">
        <div class="flex items-center">
          <button nz-button (click)="showMenu()" nzShape="round">
            <i class="fa fa-bars"></i>
          </button>

          <img class="cursor-pointer" routerLink="/" src="https://assets.fxdao.io/brand/FxDAO-logo.svg" alt="fxdao_logo" style="height: 80%">
        </div>

        <div #kitButtonContainer class="flex justify-center items-center">
<!--          <ng-container *ngIf="(publicKey$ | async) as publicKey; else notSignedIn">-->
<!--            <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzShape="round">-->
<!--              {{ publicKey | slice: 0:4}}...{{ publicKey | slice: -4}} <i class="fa fa-wallet ml-2"></i>-->
<!--            </button>-->
<!--            <nz-dropdown-menu #menu="nzDropdownMenu">-->
<!--              <ul nz-menu>-->
<!--                <li (click)="disconnect()" nz-menu-item nzDanger>Disconnect</li>-->
<!--              </ul>-->
<!--            </nz-dropdown-menu>-->

<!--          </ng-container>-->
<!--          <ng-template #notSignedIn>-->
<!--            <button (click)="connectWallet()" nz-button nzShape="round">-->
<!--              Connect <i class="fa fa-wallet ml-2"></i>-->
<!--            </button>-->
<!--          </ng-template>-->
        </div>
      </div>
    </nz-header>

    <nz-content class="p-0">
      <div class="container mx-auto">
        <router-outlet></router-outlet>
      </div>
    </nz-content>

    <nz-footer class="mt-auto">
      <div class="flex w-full justify-center">
        <small class="opacity-50 text-center">
          Made by Creit Technologies LLP for the non-profit FxDAO LLC
        </small>
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>
