import { Injectable } from '@angular/core';
import { ProfileRepository } from '../../../state/profile.repository';
import { distinctUntilChanged, firstValueFrom, map, of, Subscription, switchMap, timer } from 'rxjs';
import { WalletService } from '../wallet/wallet.service';
import { setProp } from '@ngneat/elf';
import { ProfileRpcService } from './profile-rpc.service';
import * as StellarSDK from '@stellar/stellar-sdk';
import BigNumber from 'bignumber.js';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private readonly profileRepository: ProfileRepository,
    private readonly profileRpcService: ProfileRpcService,
    private readonly walletService: WalletService,
  ) { }

  fetchAccountRecordSubscription: Subscription = this.profileRepository.publicKey$
    .pipe(distinctUntilChanged())
    .pipe(switchMap((publicKey) => {
      this.profileRpcService.updateCurrencyData$.next();
      return timer(0, 5000)
        .pipe(map(_ => publicKey));
    }))
    .pipe(switchMap((publicKey) => {
      if (!publicKey) {
        return of(undefined);
      } else {
        return this.walletService.horizonServer.loadAccount(publicKey)
          .catch(_ => undefined);
      }
    }))
    .subscribe(response => {
      this.profileRepository.store.update(setProp('accountRecord', response))
    })

  async getCurrencyBalanceLine(asset: StellarSDK.Asset): Promise<StellarSDK.Horizon.HorizonApi.BalanceLineAsset> {
    const accountRecord = await firstValueFrom(this.profileRepository.accountRecord$);

    if (!accountRecord) {
      throw new Error('Account has not been loaded');
    }

    const balanceLine = accountRecord.balances.find(b => {
      return (b.asset_type === 'credit_alphanum4' || b.asset_type === 'credit_alphanum12') &&
        b.asset_code === asset.code &&
        b.asset_issuer === asset.issuer;
    }) as StellarSDK.Horizon.HorizonApi.BalanceLineAsset;

    if (!balanceLine) {
      throw new Error(`Account doesn't trust this asset`);
    }

    return balanceLine;
  }

  async calculateAvailableBalance(balanceLine: StellarSDK.Horizon.HorizonApi.BalanceLineAsset | StellarSDK.Horizon.HorizonApi.BalanceLineNative): Promise<BigNumber> {
    const accountRecord = await firstValueFrom(this.profileRepository.accountRecord$);
    if (!accountRecord) {
      return new BigNumber(0);
    }

    let finalAmount = new BigNumber(0);

    if (balanceLine.asset_type === 'native') {
      const minimumBase = new BigNumber(2)
        .plus(accountRecord.subentry_count)
        .plus((accountRecord as any).num_sponsoring || 0)
        .minus((accountRecord as any).num_sponsored || 0)
        .multipliedBy(0.5);

      finalAmount = finalAmount.minus(minimumBase);
    }

    switch (balanceLine.asset_type) {
      case 'native':
      case 'credit_alphanum4':
      case 'credit_alphanum12':
        finalAmount = finalAmount.plus(new BigNumber(balanceLine.balance))
          .minus(new BigNumber(balanceLine.selling_liabilities));
        break;
    }

    return finalAmount.isLessThanOrEqualTo(0) ? new BigNumber(0) : finalAmount;
  }
}
