import { Inject, Injectable } from '@angular/core';
import * as StellarSDK from '@stellar/stellar-sdk';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RpcService {

  rpc: StellarSDK.rpc.Server = new StellarSDK.rpc.Server(
    this.env.sorobanRpcUrl,
    { allowHttp: !this.env.production }
  );

  constructor(
    @Inject('env')
    private readonly env: typeof environment,
  ) { }

  async waitUntilTxApproved(hash: string) {
    let completed = false;
    let attempts = 0;
    while (!completed) {
      const tx = await this.rpc.getTransaction(hash);

      if (tx.status === 'NOT_FOUND') {
        await new Promise(r => setTimeout(r, 1000))
      } else if (tx.status === 'SUCCESS') {
        completed = true;
      } else {
        throw new Error(`Transaction ${hash} failed.`);
      }

      attempts++;

      if (attempts >= 10) {
        throw new Error(`The network did not accept the tx ${hash} in less than 10 seconds.`);
      }
    }
  }

  async getAssetBalance(params: { source: string; account: string; assetContract: string }): Promise<bigint> {
    let source: StellarSDK.Account;
    try {
      source = await this.rpc.getAccount(params.source);
    } catch (e) {
      console.error('Account not found', e);
      return 0n;
    }

    let balance: bigint;
    try {
      const contract = new StellarSDK.Contract(params.assetContract);
      const tx = new StellarSDK.TransactionBuilder(source, {
        fee: this.env.defaultFee,
        networkPassphrase: this.env.networkPassphrase
      })
        .addOperation(
          contract.call(
            'balance',
            new StellarSDK.Address(params.account).toScVal()
          ),
        )
        .setTimeout(210)
        .build();

      const responseValue = await this.rpc.simulateTransaction(tx)
        .then(response => {
          if ('error' in response && response.error) {
            throw response.error;
          }
          return StellarSDK.xdr.ScVal.fromXDR(((response as StellarSDK.rpc.Api.SimulateTransactionSuccessResponse).result as StellarSDK.rpc.Api.SimulateHostFunctionResult).retval.toXDR())
        });

      balance = StellarSDK.scValToBigInt(responseValue)
    } catch (e) {
      console.error(e);
      balance = 0n;
    }

    return balance;
  }
}
