import { createStore, distinctUntilArrayItemChanged, withProps } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  withUIEntities
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Denomination } from '../../libs/FxDAO-SDK';

export interface CurrencyUI {
  _id: string;
  gettingCurrencyData: boolean;
  gettingStats: boolean;
}

export interface Currency {
  _id: string;
  denomination: Denomination;
  code: string;
  issuer: string;
  contractId: string;

  props?: {
    active: boolean;
    last_update: string;
    rate: string;
  };

  currencyStats?: {
    total_vaults: string;
    total_debt: string;
    total_col: string;
  };


  vaultsConditions?: {
    min_col_rate: string;
    min_debt_creation: string;
    opening_col_rate: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CurrenciesProps {
}

@Injectable({ providedIn: 'root' })
export class CurrenciesRepository {
  public store = this.createStore();

  currencies$: Observable<Currency[]> = this.store.pipe(selectAllEntities())
    .pipe(distinctUntilArrayItemChanged());

  private createStore(): typeof store {
    const store = createStore(
      { name: 'currencies' },
      withProps<CurrenciesProps>({}),
      withEntities<Currency, '_id'>({ idKey: '_id' }),
      withUIEntities<CurrencyUI, '_id'>({ idKey: '_id' })
    );

    return store;
  }
}
