import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import * as StellarSDK from '@stellar/stellar-sdk';
import { selectAllEntities, withEntities, withUIEntities } from '@ngneat/elf-entities';

export interface ProfileVaultUI {
  _id: string;
  gettingStats: boolean;
}

export interface ProfileVault {
  _id: string; // This is the currency id
  total_debt: string
  total_col: string;
}

export interface ProfileProps {
  publicKey?: string;
  accountRecord?: StellarSDK.Horizon.AccountResponse;
}

@Injectable({ providedIn: 'root' })
export class ProfileRepository {
  public store = this.createStore();

  public publicKey$ = this.store.pipe(select(state => state.publicKey));
  public accountRecord$ = this.store.pipe(select(state => state.accountRecord));
  public profileVaults$ = this.store.pipe(selectAllEntities());

  constructor() {}

  private createStore() {
    return createStore(
      {name: 'profile'},
      withProps<ProfileProps>({}),
      withEntities<ProfileVault, '_id'>({ idKey: '_id' }),
      withUIEntities<ProfileVaultUI, '_id'>({ idKey: '_id' }),
    );
  }
}
