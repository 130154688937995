import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module')
          .then(m => m.DashboardModule),
      },
      {
        path: 'vaults',
        loadChildren: () => import('./modules/vaults/vaults.module')
          .then(m => m.VaultsModule),
      },
      {
        path: 'redeem',
        loadChildren: () => import('./modules/redeem/redeem.module')
          .then(m => m.RedeemModule),
      },
      {
        path: 'liquidations',
        loadChildren: () => import('./modules/liquidations/liquidations.module')
          .then(m => m.LiquidationsModule),
      },
      {
        path: 'locking-pools',
        loadChildren: () => import('./modules/locking-pools/locking-pools.module')
          .then(m => m.LockingPoolsModule),
      },
      // {
      //   path: 'safety-pools',
      //   loadChildren: () => import('./modules/safety-pools/safety-pools.module')
      //     .then(m => m.SafetyPoolsModule),
      // },
      // {
      //   path: 'swaps',
      //   loadChildren: () => import('./modules/swaps/swaps.module')
      //     .then(m => m.SwapsModule),
      // },
      // {
      //   path: 'governance',
      //   loadChildren: () => import('./modules/governance/governance.module')
      //     .then(m => m.GovernanceModule),
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
