import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ProfileRepository } from '../../../state/profile.repository';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { WalletService } from '../../services/wallet/wallet.service';
import { VaultsRepository } from '../../../state/vaults.repository';
import { setProp } from '@ngneat/elf';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements AfterViewInit {
  @ViewChild('drawerTemplate', { static: false }) drawerTemplate?: TemplateRef<{
    $implicit: { value: string };
    drawerRef: NzDrawerRef<string>;
  }>;

  @ViewChild('kitButtonContainer') kitButtonContainer!: ElementRef<any>

  publicKey$ = this.profileRepository.publicKey$;

  constructor(
    private readonly profileRepository: ProfileRepository,
    private readonly vaultsRepository: VaultsRepository,
    private readonly nzDrawerService: NzDrawerService,
    private readonly walletService: WalletService,
  ) {}

  ngAfterViewInit() {
    this.walletService.kit.createButton({
      container: this.kitButtonContainer.nativeElement,
      onConnect: ({ address}) => {
        this.profileRepository.store.update(setProp('publicKey', address));
      },
      onDisconnect: () => {
        this.profileRepository.store.reset();
        this.vaultsRepository.store.reset();
      }
    })
  }

  showMenu() {
    this.nzDrawerService.create({
      nzPlacement: 'left',
      nzTitle: 'Website menu',
      nzContent: this.drawerTemplate,
    });
  }
}
