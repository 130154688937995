import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CurrenciesRepository, Currency } from '../../../state/currencies.repository';
import * as StellarSDK from '@stellar/stellar-sdk';
import { upsertEntities } from '@ngneat/elf-entities';
import { Denomination } from '../../../../libs/FxDAO-SDK';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  constructor(
    @Inject('env')
    private readonly env: typeof environment,
    private readonly currenciesRepository: CurrenciesRepository,
  ) {
    this.loadCurrenciesIntoAppState();
  }

  private loadCurrenciesIntoAppState(): void {
    const currencies: Currency[] = [{
      _id: this.env.usdAsset,
      code: this.env.usdAsset.split(':')[0],
      issuer: this.env.usdAsset.split(':')[1],
      contractId: this.env.usdContractId,
      denomination: this.getDenominationFromAsset(
        new StellarSDK.Asset(
          this.env.usdAsset.split(':')[0],
          this.env.usdAsset.split(':')[1]
        )
      ),
    }, {
      _id: this.env.eurAsset,
      code: this.env.eurAsset.split(':')[0],
      issuer: this.env.eurAsset.split(':')[1],
      contractId: this.env.eurContractId,
      denomination: this.getDenominationFromAsset(
        new StellarSDK.Asset(
          this.env.eurAsset.split(':')[0],
          this.env.eurAsset.split(':')[1]
        )
      ),
    }, {
      _id: this.env.gbpAsset,
      code: this.env.gbpAsset.split(':')[0],
      issuer: this.env.gbpAsset.split(':')[1],
      contractId: this.env.eurContractId,
      denomination: this.getDenominationFromAsset(
        new StellarSDK.Asset(
          this.env.gbpAsset.split(':')[0],
          this.env.gbpAsset.split(':')[1]
        )
      ),
    }];

    this.currenciesRepository.store.update(upsertEntities(currencies));
  }

  getDenominationFromAsset(asset: StellarSDK.Asset): Denomination {
    const denomination: Denomination = {
      USDx: 'USD',
      EURx: 'EUR',
      GBPx: 'GBP',
    }[asset.code] as Denomination;

    if (!denomination) {
      throw new Error(`Asset code ${denomination} is not supported`);
    }

    return denomination;
  }

  getCanonicalByDenomination(denomination: Denomination): string {
    const canonical = {
      USD: this.env.usdAsset,
      EUR: this.env.eurAsset,
      GBP: this.env.gbpAsset,
    }[denomination];

    if (!canonical) {
      throw new Error(`Asset code ${denomination} is not supported`);
    }

    return canonical;
  }

  getAssetByDenomination(denomination: Denomination): StellarSDK.Asset {
    let assetString: string = {
      USD: this.env.usdAsset,
      EUR: this.env.eurAsset,
      GBP: this.env.gbpAsset,
    }[denomination] as string;

    if (!assetString) {
      throw new Error(`Denomination ${denomination} is not supported`);
    }

    return new StellarSDK.Asset(
      assetString.split(':')[0],
      assetString.split(':')[1]
    );
  }

  getAssetByAssetCode(assetCode: string): StellarSDK.Asset {
    let assetString: string | undefined = {
      USDx: this.env.usdAsset,
      EURx: this.env.eurAsset,
    }[assetCode];

    if (!assetString) {
      throw new Error(`Asset code ${assetCode} is not supported`);
    }

    return new StellarSDK.Asset(
      assetString.split(':')[0],
      assetString.split(':')[1]
    );
  }

  getDenominationFromAssetCode(assetCode: string): Denomination {
    const denomination = {
      USDx: 'USD',
      EURx: 'EUR',
      GBPx: 'GBP'
    }[assetCode];

    if (!denomination) {
      throw new Error(`Asset code ${assetCode} is not supported`);
    }

    return denomination as Denomination;
  }
}
