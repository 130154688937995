import { createStore, distinctUntilArrayItemChanged, select, withProps } from '@ngneat/elf';
import { withEntities, selectAllEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CurrenciesRepository } from './currencies.repository';
import { StateToggleDecoratorFactory } from './state-toggle.decorator';

export interface IVault {
  _id: string;
  owner: string;
  assetCode: string;
  index: string;
  collateral: string;
  debt: string;
  collateralRatio: string;
  liquidationPrice: string;
  expectedProfit: string;
  canBeLiquidated: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VaultsProps {
  liquidatingVaults: boolean;
  gettingUsersVaults: boolean;
  gettingVaultsToLiquidate: boolean;
  creatingVault: boolean;
  updatingVault: boolean;

  redeeming: boolean;
}

export const store = createStore(
  { name: 'vaults' },
  withProps<VaultsProps>({
    liquidatingVaults: false,
    gettingUsersVaults: false,
    gettingVaultsToLiquidate: false,
    creatingVault: false,
    updatingVault: false,
    redeeming: false,
  }),
  withEntities<IVault, '_id'>({ idKey: '_id' })
);

@Injectable({ providedIn: 'root' })
export class VaultsRepository {
  public store = store;

  liquidatingVaults$ = this.store.pipe(select(state => state.liquidatingVaults));
  gettingUsersVaults$ = this.store.pipe(select(state => state.gettingUsersVaults));
  gettingVaultsToLiquidate$ = this.store.pipe(select(state => state.gettingVaultsToLiquidate));
  creatingVault$ = this.store.pipe(select(state => state.creatingVault));
  updatingVault$ = this.store.pipe(select(state => state.updatingVault));
  redeeming$ = this.store.pipe(select(state => state.redeeming));

  vaults$: Observable<IVault[]> = this.store
    .pipe(selectAllEntities())
    .pipe(distinctUntilArrayItemChanged())
    // .pipe(switchMap(vaults => {
    //   return this.currenciesRepository.currencies$
    //     .pipe(distinctUntilArrayItemChanged())
    //     .pipe(map((currencies): IVault[] => {
    //       return vaults.map(vault => {
    //         const currency = currencies.find(c => c.code === vault.assetCode);
    //         const collateralRatio = new BigNumber(vault.collateral || 0)
    //           .multipliedBy(currency?.props?.rate || 0)
    //           .dividedBy(vault.debt)
    //           .toString();
    //         const canBeLiquidated = new BigNumber(currency?.vaultsConditions?.min_col_rate || 0)
    //           .isGreaterThan(collateralRatio);
    //         const liquidationPrice = new BigNumber(vault.debt)
    //           .multipliedBy(1.1)
    //           .dividedBy(vault.collateral)
    //           .toString();
    //
    //         return {
    //           ...vault,
    //           collateralRatio,
    //           canBeLiquidated,
    //           liquidationPrice,
    //         };
    //       });
    //     }));
    // }));

  constructor(
    private readonly currenciesRepository: CurrenciesRepository
  ) {}
}

export const CreatingVault = StateToggleDecoratorFactory<VaultsProps>(store, 'creatingVault');
export const UpdatingVault = StateToggleDecoratorFactory<VaultsProps>(store, 'updatingVault');
export const GettingVaultsToLiquidate = StateToggleDecoratorFactory<VaultsProps>(store, 'gettingVaultsToLiquidate');
export const RedeemingVault = StateToggleDecoratorFactory<VaultsProps>(store, 'redeeming');
export const LiquidatingVaults = StateToggleDecoratorFactory<VaultsProps>(store, 'liquidatingVaults');
