import { Inject, Injectable } from '@angular/core';
import { Subject, Subscription, switchMap, take, timer } from 'rxjs';
import { CurrenciesRepository, Currency } from '../../../state/currencies.repository';
import { environment } from '../../../../environments/environment';
import * as StellarSDK from '@stellar/stellar-sdk';
import { UIEntitiesRef, updateEntities } from '@ngneat/elf-entities';
import { LedgerDataService } from '../ledger-data/ledger-data.service';
import BigNumber from 'bignumber.js';
import { RpcService } from '../rpc/rpc.service';
import { FxDaoSdkService } from '../fxdao-sdk/fxdao-sdk.service';
import { scValToNative } from '@stellar/stellar-sdk';
import { Denomination, VaultsTypes } from '../../../../libs/FxDAO-SDK';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesRpcService {

  updateCurrencyData$: Subject<void> = new Subject<void>();

  constructor(
    @Inject('env')
    private readonly env: typeof environment,
    private readonly currenciesRepository: CurrenciesRepository,
    private readonly ledgerDataService: LedgerDataService,
    private readonly rpcService: RpcService,
    private readonly fxDaoSdkService: FxDaoSdkService,
  ) { }

  updaterSubscription: Subscription = this.updateCurrencyData$.asObservable()
    .pipe(switchMap(() => timer(0, 15000)))
    .pipe(switchMap(() => {
      return this.currenciesRepository.currencies$.pipe(take(1));
    }))
    .subscribe((currencies) => {
      currencies.map(currency => {
        this.getCurrencyProps(currency)
          .then()
          .catch();

        this.getCurrenciesStatsAndConditions(currency)
          .then()
          .catch();
      });
    });

  async getCurrencyProps(currency: Currency) {
    this.currenciesRepository.store.update(
      updateEntities([currency._id], { gettingCurrencyData: true }, { ref: UIEntitiesRef })
    );

    let responseValue: StellarSDK.xdr.ScVal;
    try {
      const account = new StellarSDK.Account(this.env.accountForSimulations, "0");
      const contract = new StellarSDK.Contract(this.env.vaultsContractId);
      const tx = new StellarSDK.TransactionBuilder(account, {
        fee: this.env.defaultFee,
        networkPassphrase: this.env.networkPassphrase
      })
        .addOperation(
          contract.call(
            'get_currency',
            StellarSDK.xdr.ScVal.scvSymbol(currency.denomination),
          ),
        )
        .setTimeout(210)
        .build();

      responseValue = await this.rpcService.rpc.simulateTransaction(tx)
        .then((response: StellarSDK.SorobanRpc.Api.SimulateTransactionResponse) => {
          if ('error' in response && response.error) {
            throw response.error;
          }
          return StellarSDK.xdr.ScVal.fromXDR(((response as StellarSDK.SorobanRpc.Api.SimulateTransactionSuccessResponse).result as StellarSDK.SorobanRpc.Api.SimulateHostFunctionResult).retval.toXDR());
        });
    } catch (e) {
      this.currenciesRepository.store.update(
        updateEntities([currency._id], { gettingCurrencyData: false }, { ref: UIEntitiesRef })
      );
      throw e;
    }

    let oracleResponseValue: StellarSDK.xdr.ScVal;
    try {
      const account = new StellarSDK.Account(this.env.accountForSimulations, "0");
      const contract = new StellarSDK.Contract(this.env.oracleContract);
      const tx = new StellarSDK.TransactionBuilder(account, {
        fee: this.env.defaultFee,
        networkPassphrase: this.env.networkPassphrase
      })
        .addOperation(
          contract.call(
            'lastprice',
            new StellarSDK.Address(this.env.vaultsContractId).toScVal(),
            StellarSDK.xdr.ScVal.scvVec([
              StellarSDK.nativeToScVal('Other', { type: 'symbol' }),
              StellarSDK.nativeToScVal(currency.denomination, { type: 'symbol' })
            ]),
          ),
        )
        .setTimeout(210)
        .build();

      oracleResponseValue = await this.rpcService.rpc.simulateTransaction(tx)
        .then((response: StellarSDK.SorobanRpc.Api.SimulateTransactionResponse) => {
          if (StellarSDK.SorobanRpc.Api.isSimulationError(response)) {
            throw response.error;
          }
          if (!response.result) {
            throw 'There was an unexpected error, contact support code: 00023';
          }
          return StellarSDK.xdr.ScVal.fromXDR(response.result.retval.toXDR());
        });
    } catch (e) {
      this.currenciesRepository.store.update(
        updateEntities([currency._id], { gettingCurrencyData: false }, { ref: UIEntitiesRef })
      );
      throw e;
    }

    const values: any[] = responseValue.value() as any[];
    const priceData: { price: bigint; timestamp: bigint; } = scValToNative(oracleResponseValue);

    const active: boolean = this.ledgerDataService.findValue({ target: 'active', values })
      .val().value().value;
    const symbol: string = Buffer.from(
      this.ledgerDataService.findValue({ target: 'denomination', values })
        .val().value()
    ).toString('utf-8');


    this.currenciesRepository.store.update(
      updateEntities([currency._id], {
        props: {
          active,
          symbol: symbol as Denomination,
          last_update: new BigNumber(priceData.timestamp.toString()).div('10000000').toString(),
          rate: new BigNumber(priceData.price.toString()).div('10000000').toString(),
        }
      }),
      updateEntities([currency._id], { gettingCurrencyData: false }, { ref: UIEntitiesRef })
    );
  }

  async getCurrenciesStatsAndConditions(currency: Currency) {
    this.currenciesRepository.store.update(
      updateEntities([currency._id], { gettingCurrencyData: true }, { ref: UIEntitiesRef })
    );

    let vaultsInfo: VaultsTypes['VaultsInfo'];

    try {
      vaultsInfo = await this.fxDaoSdkService.vaultsContract
        .getVaultsInfo({ denomination: currency.denomination });
    } catch (e) {
      this.currenciesRepository.store.update(
        updateEntities([currency._id], { gettingCurrencyData: false }, { ref: UIEntitiesRef })
      );
      throw e;
    }

    this.currenciesRepository.store.update(
      updateEntities([currency._id], {
        vaultsConditions: {
          min_col_rate: new BigNumber(vaultsInfo.min_col_rate.toString()).dividedBy('10000000').toFixed(7),
          min_debt_creation: new BigNumber(vaultsInfo.min_debt_creation.toString()).dividedBy('10000000').toFixed(7),
          opening_col_rate: new BigNumber(vaultsInfo.opening_col_rate.toString()).dividedBy('10000000').toFixed(7),
        },
        currencyStats: {
          total_vaults: vaultsInfo.total_vaults.toString(),
          total_debt: new BigNumber(vaultsInfo.total_debt.toString()).dividedBy('10000000').toFixed(7),
          total_col: new BigNumber(vaultsInfo.total_col.toString()).dividedBy('10000000').toFixed(7),
        }
      }),
      updateEntities([currency._id], { gettingCurrencyData: false }, { ref: UIEntitiesRef })
    );
  }
}
