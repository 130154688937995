"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultsErrors = void 0;
exports.VaultsErrors = {
    20: `Panic mode is enabled`,
    50: `There was an unexpected error, please contact support`,
    100: `Core state has already been set`,
    101: `Fee value is invalid`,
    200: `Vaults info has not started`,
    201: `There are no vaults`,
    300: `Invalid min debt amount`,
    310: `InvalidMinCollateralAmount`,
    400: `InvalidOpeningCollateralRatio`,
    500: `Vault doesn't exist`,
    501: `User already has a vault with this denomination`,
    502: `User vault index is invalid`,
    503: `User vault can't be liquidated`,
    504: `Invalid prev vault index`,
    505: `Prev vault cant be none`,
    506: `Prev vault doesn't exist`,
    507: `The next index of the prev vault is lower than the new vault index`,
    508: `The next index of the prev vault is invalid`,
    509: `Index provided is not the one saved`,
    510: `Next prev vault should be none`,
    511: `Not enough vaults to liquidate`,
    512: `InvalidPrevKeyDenomination`,
    600: `Deposit amount is more than the total debt`,
    700: `Collateral rate is under the minimum allowed`,
    800: `Not enough funds to redeem`,
    900: `Currency is already created`,
    901: `Currency doesnt exist`,
    902: `Currency is inactive`,
};
