import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LedgerDataService {

  constructor() { }

  findValue(params: { target: string, values: any[] }): any {
    return params.values.find((v: any) => {
      return Buffer.from(v.key().value()).toString('utf-8') === params.target;
    });
  }
}
